import { defaultTheme as theme, comTheme } from '@geberit/gdds';

export const headlineSizes = {
  default: {
    h1: {
      xsmall: theme.fontTokens.font05,
      medium: theme.fontTokens.font03,
      large: theme.fontTokens.font02,
      xlarge: theme.fontTokens.font01,
    },
    h2: {
      xsmall: theme.fontTokens.font06,
      medium: theme.fontTokens.font05,
      large: theme.fontTokens.font04,
      xlarge: theme.fontTokens.font03,
    },
    h3: {
      xsmall: theme.fontTokens.font07,
      medium: theme.fontTokens.font06,
      large: theme.fontTokens.font05,
      xlarge: theme.fontTokens.font04,
    },
    h4: {
      xsmall: theme.fontTokens.font08,
      medium: theme.fontTokens.font07,
      large: theme.fontTokens.font06,
      xlarge: theme.fontTokens.font05,
    },
    h5: {
      xsmall: theme.fontTokens.font09,
      medium: theme.fontTokens.font08,
      large: theme.fontTokens.font07,
      xlarge: theme.fontTokens.font06,
    },
    h6: {
      xsmall: theme.fontTokens.font10,
      medium: theme.fontTokens.font09,
      large: theme.fontTokens.font09,
      xlarge: theme.fontTokens.font09,
    },
  },
  gdds: {
    h1: {
      xsmall: theme.fontTokens.font05,
      medium: theme.fontTokens.font03,
      large: theme.fontTokens.font02,
      xlarge: comTheme.fontTokens.font01,
    },
    h2: {
      xsmall: theme.fontTokens.font07,
      medium: theme.fontTokens.font06,
      large: theme.fontTokens.font05,
      xlarge: theme.fontTokens.font04,
    },
    h3: {
      xsmall: theme.fontTokens.font07,
      medium: theme.fontTokens.font06,
      large: theme.fontTokens.font05,
      xlarge: theme.fontTokens.font04,
    },
    h4: {
      xsmall: theme.fontTokens.font08,
      medium: theme.fontTokens.font07,
      large: theme.fontTokens.font06,
      xlarge: theme.fontTokens.font05,
    },
    h5: {
      xsmall: theme.fontTokens.font09,
      medium: theme.fontTokens.font08,
      large: theme.fontTokens.font07,
      xlarge: theme.fontTokens.font06,
    },
    h6: {
      xsmall: theme.fontTokens.font10,
      medium: theme.fontTokens.font09,
      large: theme.fontTokens.font09,
      xlarge: theme.fontTokens.font09,
    },
  },
  xy: {
    h1: {
      xsmall: theme.fontTokens.font05,
      medium: theme.fontTokens.font03,
      large: theme.fontTokens.font02,
      xlarge: comTheme.fontTokens.font01,
    },
    h2: {
      xsmall: theme.fontTokens.font07,
      medium: theme.fontTokens.font06,
      large: theme.fontTokens.font05,
      xlarge: theme.fontTokens.font04,
    },
    h3: {
      xsmall: theme.fontTokens.font07,
      medium: theme.fontTokens.font06,
      large: theme.fontTokens.font05,
      xlarge: theme.fontTokens.font04,
    },
    h4: {
      xsmall: theme.fontTokens.font08,
      medium: theme.fontTokens.font07,
      large: theme.fontTokens.font06,
      xlarge: theme.fontTokens.font05,
    },
    h5: {
      xsmall: theme.fontTokens.font09,
      medium: theme.fontTokens.font08,
      large: theme.fontTokens.font07,
      xlarge: theme.fontTokens.font06,
    },
    h6: {
      xsmall: theme.fontTokens.font10,
      medium: theme.fontTokens.font09,
      large: theme.fontTokens.font09,
      xlarge: theme.fontTokens.font09,
    },
  },
  nord: {
    h1: {
      xsmall: theme.fontTokens.font05,
      medium: theme.fontTokens.font03,
      large: theme.fontTokens.font02,
      xlarge: theme.fontTokens.font01,
    },
    h2: {
      xsmall: theme.fontTokens.font07,
      medium: theme.fontTokens.font06,
      large: theme.fontTokens.font05,
      xlarge: theme.fontTokens.font04,
    },
    h3: {
      xsmall: theme.fontTokens.font09,
      medium: theme.fontTokens.font08,
      large: theme.fontTokens.font07,
      xlarge: theme.fontTokens.font07,
    },
    h4: {
      xsmall: theme.fontTokens.font10,
      medium: theme.fontTokens.font10,
      large: theme.fontTokens.font10,
      xlarge: theme.fontTokens.font10,
    },
  },
  kolo: {
    h1: {
      xsmall: theme.fontTokens.font05,
      medium: theme.fontTokens.font03,
      large: theme.fontTokens.font02,
      xlarge: theme.fontTokens.font01,
    },
    h2: {
      xsmall: theme.fontTokens.font06,
      medium: theme.fontTokens.font05,
      large: theme.fontTokens.font04,
      xlarge: theme.fontTokens.font03,
    },
    h3: {
      xsmall: theme.fontTokens.font07,
      medium: theme.fontTokens.font06,
      large: theme.fontTokens.font05,
      xlarge: theme.fontTokens.font04,
    },
    h4: {
      xsmall: theme.fontTokens.font08,
      medium: theme.fontTokens.font07,
      large: theme.fontTokens.font06,
      xlarge: theme.fontTokens.font05,
    },
    h5: {
      xsmall: theme.fontTokens.font09,
      medium: theme.fontTokens.font08,
      large: theme.fontTokens.font07,
      xlarge: theme.fontTokens.font07,
    },
    h6: {
      xsmall: theme.fontTokens.font10,
      medium: theme.fontTokens.font09,
      large: theme.fontTokens.font09,
      xlarge: theme.fontTokens.font09,
    },
  },
  twyford: {
    h1: {
      xsmall: theme.fontTokens.font05,
      medium: theme.fontTokens.font03,
      large: theme.fontTokens.font02,
      xlarge: theme.fontTokens.font01,
    },
    h2: {
      xsmall: theme.fontTokens.font06,
      medium: theme.fontTokens.font05,
      large: theme.fontTokens.font04,
      xlarge: theme.fontTokens.font03,
    },
    h3: {
      xsmall: theme.fontTokens.font07,
      medium: theme.fontTokens.font06,
      large: theme.fontTokens.font05,
      xlarge: theme.fontTokens.font04,
    },
    h4: {
      xsmall: theme.fontTokens.font08,
      medium: theme.fontTokens.font07,
      large: theme.fontTokens.font06,
      xlarge: theme.fontTokens.font05,
    },
    h5: {
      xsmall: theme.fontTokens.font09,
      medium: theme.fontTokens.font08,
      large: theme.fontTokens.font07,
      xlarge: theme.fontTokens.font07,
    },
    h6: {
      xsmall: theme.fontTokens.font10,
      medium: theme.fontTokens.font09,
      large: theme.fontTokens.font09,
      xlarge: theme.fontTokens.font09,
    },
  },
};

import key from 'weak-key';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

// styles
import styles from './inner-html.module.scss';

// types
import type { InnerHtmlProps } from './inner-html.types';

// components
import { CmsLink } from '../Link/CmsLink';
import { Icon } from '../Icon/Icon';
import { FontWeights, Formats, Headline } from '../ContentElements/Headline';
import { InlineEdit } from '../ContentCreator/InlineEdit';

// utils
import { useUsercentrics } from 'features/cookie-consent';
import { replaceSharpS } from 'utils/replaceSharpS';
import { classNameBuilder as buildClassName } from 'utils/classNameBuilder';
import { useKolo } from 'utils/hooks/use-kolo';
import { isEmpty } from 'utils/is-empty';
import { useUsercentricsWorkaround } from '../../features/cookie-consent/hooks/use-usercentrics-workaround';

export function InnerHtml({
  as: Element,
  content,
  isGdds = false,
  className,
  previewId,
  isSectionReference,
  ...props
}: Readonly<InnerHtmlProps>) {
  const uc = useUsercentrics();
  const isKolo = useKolo();
  const { setUcOpen } = useUsercentricsWorkaround();
  if (isEmpty(content)) return null;

  // replace a tag with Routerlink
  const transform = (
    node: {
      type: string;
      name: keyof JSX.IntrinsicElements;
      attribs: Record<string, string>;
      children: any;
    },
    index: number,
  ) => {
    if (node.type === 'tag' && node.name === 'a') {
      const {
        attribs: {
          target,
          href,
          class: tagClassName,
          'data-local-medium': localMedium,
          targeturl,
          'data-download-size': downloadSize,
          'data-download-icon': downloadIcon,
        },
        children,
      } = node;

      if (tagClassName?.includes('_openUsercentricsLayer') || tagClassName?.includes('ucShow_')) {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              uc?.showModal();
              setUcOpen(true);
            }}
          >
            {children.pop()?.data}
          </a>
        );
      }

      let finalTarget = target;
      const isInternal =
        (href && !href.includes('http')) || localMedium ? 'internal_link' : 'external_link';

      if (href?.includes('javascript:')) {
        finalTarget = '_popup';
      }

      const link = {
        target: href,
        window: finalTarget || '',
        type: isInternal,
        localMedium,
        targeturl,
        showArrow: isGdds && downloadIcon !== 'download-pdf' && !tagClassName?.includes('noArrow'),
        download: downloadIcon || downloadSize ? true : undefined,
      };

      return (
        <span key={key(link)}>
          <CmsLink
            className={buildClassName(
              tagClassName,
              isGdds && downloadIcon === 'download-pdf' && styles.isDownload,
            )}
            link={link}
            key={key(link)}
            standardFontSize={false}
            inContext
            icon={isGdds && downloadIcon === 'download-pdf' ? 'Pdf' : undefined}
          >
            <span>
              {children?.[0]?.data}
              {downloadIcon && (
                <>
                  &nbsp; (
                  {!isGdds && (
                    <>
                      <Icon symbol={downloadIcon} />
                      ,&nbsp;
                    </>
                  )}
                  {downloadSize?.trim()})
                </>
              )}
            </span>
          </CmsLink>
        </span>
      );
    }
    if (isGdds && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7'].includes(node.name)) {
      return (
        <Headline
          key={key(node)}
          format={Formats[node.name]}
          isUppercase={false}
          titleFontWeight={isKolo ? FontWeights.bold : undefined}
          subtitleFontWeight={isKolo ? FontWeights.bold : undefined}
        >
          {node.children.map((childrenNode, childrenIndex) =>
            convertNodeToElement(childrenNode, childrenIndex, transform),
          )}
        </Headline>
      );
    }
    return convertNodeToElement(node, index, transform);
  };

  // generate react html
  const html = ReactHtmlParser(String(content), {
    decodeEntities: true,
    transform,
  });

  const JsxElement = Element as any;

  return (
    <JsxElement
      {...props}
      className={buildClassName(className, styles.displayLineBreak, isGdds && styles.isGdds)}
    >
      <InlineEdit previewId={previewId} isSectionReference={isSectionReference}>
        {replaceSharpS(html)}
      </InlineEdit>
    </JsxElement>
  );
}
